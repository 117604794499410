







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class QrScanButton extends Vue {
  @Prop(Boolean) readonly open: boolean | undefined

  toggleQrScanner(event: Event) {
    this.$emit(this.open ? 'hide' : 'show');
  }
}
